import React from 'react';
import "../style/Navbar.css";

function Navbar() {
  return (
    <div className="Navbar">
      <div className="name">Camille Rey</div>
      {/* <button>Download CV</button> */}
    </div>
  )
}

export default Navbar; 

